<template>
  <div>
    <div class="menu-header" >
      <a-icon class="arrow" type="arrow-left" @click="$router.go(-1)" />
      <div class="title">参与者列表</div>
      <!-- <div class="menu" @click="showRefused">拒绝</div> -->
      <!-- <a-icon class="menu" type="stop" @click="showRefused"/> -->
      <div class="menu" @click="showRefused" >
        <img class="icon" src="../../assets/icons/icon-refuse.png" />
        <div class="text">拒绝签署</div>
      </div>

    </div>
    <PreviewContent ref="previewContent" style="margin-top:20px" :info="info" />
    <a-modal
      width="50%"
      title="拒绝签署"
      :visible="refusedVisible"
      @cancel="refusedVisible = false"
      @ok="toRefused"
    >
      <a-textarea v-model="refusedRemark" placeholder="请输入拒绝签署的理由" :maxLength="400" :rows="4" >
      </a-textarea>
    </a-modal>
  </div>
</template>
<script>
import PreviewContent from './components/previewContent'
import { getDetail, getApplyRefuse } from '@/api/consent/consent'

export default {
  components: { PreviewContent },
  data () {
    return {
      info: {},
      configId: '',
      refusedVisible: false,
      refusedRemark: '',
      applyId: ''
    }
  },
  mounted () {
    this.configId = this.$route.query.icfId
    this.applyId = this.$route.query.id
    console.log(this.configId)
    this.getDetail()
  },
  methods: {
    toRefused () {
      if (!this.refusedRemark) {
        this.$message.error('请输入拒绝理由')
        return
      }
      getApplyRefuse({
        applyId: this.applyId,
        revokeReason: this.refusedRemark
      }).then(res => {
        this.$message.success('拒绝成功')
        this.$router.go(-1)
      })
    },
    showRefused () {
      this.refusedVisible = true
      this.refusedRemark = ''
    },
    getDetail () {
      getDetail({ id: this.$route.query.icfId, applyId: this.applyId }).then(res => {
         const dataList = JSON.parse(JSON.stringify(res))
         if (dataList && dataList.detailList && dataList.detailList.length) {
            dataList.detailList.forEach(item => {
            this.$set(item, 'isExpand', false)
           })
         }
        if (res.propaganda === null) {
          dataList.propaganda = ''
        }
        this.info = dataList
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .menu{
    position: absolute;
    right: 0;
    cursor: pointer;
    // color: #E95A34;
    display: flex;
    .icon{
      width: 24px;
      height: 24px;
    }
    .text{
      color: #E95A34;
      margin-left: 10px;
      font-size: 16px;
    }
  }
</style>
