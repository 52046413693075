<template>
  <div>
    <a-steps :current="current" style="margin-bottom:20px" type="navigation" @change="onChange">
      <a-step title="知情文件" />
      <a-step title="知情内容" v-if="info.icfConfig != 4 && info.icfConfig != 3"/>
      <a-step title="知情列表" v-if="info.icfConfig != 4 && info.icfConfig != 3"/>
    </a-steps>
    <Xuandao
      ref="xuandao"
      v-show="current == 0"
      :content="data.fileUrl"
      @next="next"
      :icfConfig="info.icfConfig"
      :question="info.proIcfQuestionnaireList"
    />
    <Content
      ref="content"
      v-show="current == 1"
      :list="data.detailList"
      @toList="toList"
      @answer="answer"
      @finish="finish"
      @playVideo="playVideo"
      @pauseVideo="pauseVideo"
      @playAudio="playAudio"
      @pauseAudio="pauseAudio"
    />
    <List ref="list" :question="info.proIcfQuestionnaireList" v-show="current == 2" @content="reContent" @review="review" />
  </div>
</template>
<script>
import Xuandao from './xuandao'
import Content from './content'
import List from './list'
import { addLog } from '@/api/overview/subject'
export default {
  components: { Xuandao, Content, List },
  props: {
    info: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      current: 0,
      data: {}
    }
  },
  mounted () {
    this.initData()
    this.addLog('', 1)
  },
  watch: {
    info (e) {
      this.initData()
    }
  },
  methods: {
    init () {
      this.current = 0
    },
    initAnswer () {
      if (this.data && this.data.detailList && this.data.detailList.length) {
          this.data.detailList.forEach(item => {
            item.answer = '' // 1理解 2不理解
          })
        }
    },
    initData () {
      if (this.info) {
        this.data = JSON.parse(JSON.stringify(this.info))
        this.initAnswer()
        this.initContent()
      }
    },
    onChange (current, flag) {
      switch (this.current) {
        case 0:
          this.addLog('', 2)
          break
        case 1:
          if (flag !== -1) {
            this.addLog(this.data.detailList[this.$refs.content.current].id, 7)
          }

          break
        case 2:
          break
      }
      this.current = current
      if (this.current === 0) {
        this.initAnswer()
      }
      if (this.current === 1) {
        this.initContent(flag)
      }
      if (this.current === 2) {
        this.initList()
      }
      switch (current) {
        case 0:
          this.addLog('', 1)
          break
        case 1:
          this.addLog(this.data.detailList[this.$refs.content.current].id, 3)
          break
      }
    },
    initContent (flag) {
      this.$nextTick(() => {
        this.$refs.content.init(flag)
      }, 200)
    },
    initList () {
      this.$nextTick(() => {
        this.$refs.list.initData(this.data.detailList)
      }, 200)
    },
    answer (index, value) {
      if (this.data && this.data.detailList) {
        this.data.detailList[index].answer = value
        // 答题
        this.addLog(this.data.detailList[index].id, 4)
        // 关闭
        this.addLog(this.data.detailList[index].id, 7)
        // 下一题开始
        if (index < this.data.detailList.length - 1) {
          this.addLog(this.data.detailList[index + 1].id, 3)
        }
      }
    },
    next () {
      this.onChange(1, 0)
    },
    finish () {
      this.onChange(2, -1)
    },
    toList () {
      this.onChange(2)
    },
    review () {
     this.onChange(0)
    },
    reContent (index) {
      this.onChange(1, index)
    },
    playVideo (index) {
      this.addLog(this.data.detailList[index].id, 5)
    },
    pauseVideo (index) {
      this.addLog(this.data.detailList[index].id, 6)
    },
    playAudio (index) {
      this.addLog(this.data.detailList[index].id, 5)
    },
    pauseAudio (index) {
      this.addLog(this.data.detailList[index].id, 6)
    },
    addLog (icfDetailId, type) {
      // console.log('上传日志',
      // {
      //   icfDetailId: icfDetailId,
      //   type: type,
      //   applyId: this.$route.query.id,
      //   ptsNo: this.$route.query.ptsNo,
      //   proId: this.$route.query.proId
      // })

      addLog({
        icfDetailId: icfDetailId,
        type: type,
        applyId: this.$route.query.id,
        ptsNo: this.$route.query.ptsNo,
        proId: this.$route.query.proId
      }).then(res => {

      })
    }
  }
}
</script>
