<template>
  <div v-if="list && list.length">
    <div class="title">
      <span v-if="unCount >0">对于知情内容，您目前有{{ unCount }}项不理解</span>
      <span v-else >
        对于知情内容，您已全部理解
      </span>
    </div>
    <div v-for="(item,index) in list" :key="index" class="item" @click="itemClick(index)">
      <div class="subtitle" >
        {{ index + 1 }}.{{ item.title }}
      </div>
      <div class="icon">
        <a-icon v-if="valueData[item.id] >= 0" :class="valueData[item.id] == 1 ? 'right' : 'wrong'" :type="valueData[item.id] == 1 ? 'check' : 'close'" />
      </div>
    </div>
    <center>
      <!-- <a-button style="margin-top:20px" type="primary" @click="review" >重新查看</a-button> -->
      <div class="bottom-view" v-if="unCount === 0">
        <a-button icon="edit" type="primary" class="color" @click="onSign(1)">纸质签署</a-button>
        <a-button icon="edit" type="primary" @click="onSign(2)" >电子签署</a-button>
      </div>
    </center>
    <PopQuestion ref="questionRef" :applyId="$route.query.id" :researcherId="$route.query.researcherId" :question="question"></PopQuestion>
  </div>
</template>
<script>
import { getListQuestion, startSign } from '@/api/overview/subject'
import { applyPaper } from '@/api/consent/consent'
import PopQuestion from './popQueation.vue'

export default {
  components: { PopQuestion },
  props: [ 'question' ],
  data () {
    return {
      list: [],
      unCount: 0,
      valueData: {}
    }
  },
  methods: {
    itemClick (index) {
      this.$emit('content', index)
    },
    initData (data) {
      this.list = []
      if (data && data.length) {
        this.list = [...data]
      }
      this.unCount = 0
      this.valueData = {}
      getListQuestion({
        applyId: this.$route.query.id,
        proId: this.$route.query.proId
      }).then(res => {
        if (res.list) {
          res.list.forEach(item => {
            if (item.status !== 1) {
              this.unCount++
            }
            this.$set(this.valueData, item.id, item.status)
          })
        }
      })
    },
    getUnKonw () {
      let count = 0
      if (this.list && this.list.length) {
        this.list.forEach(item => {
          if (item.answer === 2) {
            count++
          }
        })
      }
      return count
    },
    review () {
      this.$emit('review')
    },
    async onSign (e) {
      if (e === 1) { // 纸质签署
        const params = {
          proId: this.$route.query.proId,
          applyId: this.$route.query.id
        }
        await applyPaper(params)
        this.$router.replace({
          name: 'ConsentDetail',
          query: { id: this.$route.query.id }
        })
      } else { // 电子签署、问卷填写
        if (this.question.length > 0) {
          this.$refs.questionRef.open()
        } else {
          await startSign({
            applyId: this.$route.query.id,
            researcherNo: this.$route.query.researcherId
          })
          this.$router.replace({
            name: 'ConsentDetail',
            query: { id: this.$route.query.id }
          })
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .title{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-size: 30px;
  }
  .item{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    .subtitle{
      cursor: pointer;
    }
    .icon{
      font-size: 20px;
    }
    .right{
      color: #1890ff;
    }
    .wrong{
      color: #ff2a31;
    }
  }
  .bottom-view{
    padding: 20px 20%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .color{
      background-color: #FF8F9B;
      border-color: #FF8F9B;
    }
  }
</style>
