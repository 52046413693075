<template>
  <div>
    <div v-if="list && list.length" class="preview">
      <div class="preview-box">
        <div class="preview-title-view">
          <div class="preview-title-view-title">
            <div style="margin-right:20px">{{ list[current].title }}</div>
          </div>
          <a-icon style="color:#1890ff;cursor:pointer" type="unordered-list" @click="toList" />
        </div>
        <audio
          v-if="list[current].voiceUrl"
          class="preview-audio"
          :src="list[current].voiceUrl | dealImageUrl"
          controls="controls"
          @play="playAudio"
          @pause="pauseAudio" />
        <center>
          <!-- <video
            ref="video"
            v-if="list[current].vedioUrl"
            class="preview-video"
            :src="list[current].vedioUrl | dealImageUrl"
            controlsList="nodownload"
            controls="controls"
            x5-playsinline="true"
            playsinline="true"
            webkit-playsinline="true"
            preload="auto"
            @play="playVideo"
            @pause="pauseVideo"
          /> -->
          <video-player
            v-if="list[current].vedioUrl"
            class="preview-video video-player vjs-custom-skin"
            ref="video"
            @canplay="canplay($event)"
            :playsinline="true"
            :options="playerOptions"
            @play="playVideo"
            @pause="pauseVideo"
          ></video-player>
        </center>
        <div v-html="list[current].content" class="preview-content" />
      </div>
      <div class="preview-answer">
        <a-button type="primary" @click="answer(1)" icon="check">理解</a-button>
        <a-button style="margin-left:20px" type="danger" icon="close" @click="answer(2)" >不理解</a-button>
      </div>
    </div>
  </div>
</template>
<script>
// 导入组件
import { videoPlayer } from 'vue-video-player'
import 'videojs-flash'
import { answerIcf } from '@/api/overview/subject'
export default {
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    }
  },
  components: {
    videoPlayer
  },
  data () {
    return {
      current: 0,
      player: ''
    }
  },
  mounted () {

  },
  computed: {
    playerOptions () { // 使用计算属性
      const playerOptionsObj = {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'true', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: '',
          src: this.$options.filters.dealImageUrl(this.list[this.current].vedioUrl)// url地址
          // src: "" //url地址
        }],
        poster: this.list[this.current].videoCover ? this.$options.filters.dealImageUrl(this.list[this.current].videoCover) : '', // 你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
      return playerOptionsObj
  }
  },
  methods: {
    init (index = 0) {
      this.current = index

      this.play()
    },
    canplay (player) {
       this.player = player
    },
    play () {
      // this.$nextTick(() => {
      //   if (this.$refs.video !== undefined) {
      //     const el = this.$refs.video.player

      //     // el.posterSetted = false
      //     // el.addEventListener('timeupdate', () => {
      //     //   if (el.posterSetted) {
      //     //     el.pause()
      //     //     el.currentTime = 0
      //     //     el.posterSetted = false
      //     //   }
      //     // })
      //     if (el) {
      //        el.play()
      //     setTimeout(() => {
      //       el.pause()
      //     }, 200)
      //     }
      //   }
      // })
    },
    answer (val) {
      answerIcf({
        icfDetailId: this.list[this.current].id,
        applyId: this.$route.query.id,
        proId: this.$route.query.proId,
        ptsNo: this.$route.query.ptsNo,
        status: val === 1 ? 1 : 0
      }).then(res => {
        this.$emit('answer', this.current, val)
        if (this.current < this.list.length - 1) {
          this.current++
          this.play()
        } else {
          // 到最后一条了
          this.$emit('finish')
        }
      })
    },
    checkStyle (val) {
      console.log('样式', val)
      if (val) {
        val = val.replace(/<img/g, '<img style="maxWidth: 100%"')
      }
      return val
    },
    toList () {
      this.$emit('toList')
    },
    playVideo () {
      this.$emit('playVideo', this.current)
    },
    pauseVideo () {
      this.$emit('pauseVideo', this.current)
    },
    playAudio () {
      this.$emit('playAudio', this.current)
    },
    pauseAudio () {
      this.$emit('pauseAudio', this.current)
    }
  }
}
</script>
<style lang="less" scoped>
  .preview{
    display: flex;
    flex-direction: column;
    &-title-view{
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      &-title{
        display: flex;
        align-items: center;
      }
    }
    &-audio{
      margin-top: 10px;
      width: 100%;
    }
    &-video{
      margin: 20px auto 0 auto;
      width: 100%;
      height: auto;
    }
    &-content{
      margin-top: 20px;
      ::v-deep {
        img {
          width: 100%;
        }
      }
    }
    &-answer{
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
    }
  }
  .preview-box {
    max-height: 800px;
    overflow: auto;
  }
</style>
