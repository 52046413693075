<template>
  <div>
    <!-- <div v-html="content" class="content"> -->
    <div id="demo" class="content"></div>
    <center v-if="icfConfig != 4 && icfConfig != 3">
      <a-button style="margin-top:20px" type="primary" @click="next" >下一步</a-button>
    </center>
    <center v-else>
      <!-- <a-button style="margin-top:20px" type="primary" @click="review" >重新查看</a-button> -->
      <div class="bottom-view">
        <a-button icon="edit" type="primary" class="color" @click="onSign(1)">纸质签署</a-button>
        <a-button icon="edit" type="primary" @click="onSign(2)" >电子签署</a-button>
      </div>
    </center>
    <PopQuestion ref="questionRef" :applyId="$route.query.id" :researcherId="$route.query.researcherId" :question="question"></PopQuestion>
  </div>

</template>
<script>
import Pdfh5 from 'pdfh5'
import 'pdfh5/css/pdfh5.css'
import { startSign } from '@/api/overview/subject'
import { applyPaper } from '@/api/consent/consent'
import PopQuestion from './popQueation.vue'

export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    icfConfig: {
      type: [String, Number],
      default: ''
    },
    question: {
      type: Array,
      default: () => []
    }
  },
  components: { PopQuestion },
  data () {
    return {
      pdfh5: null
    }
  },
  watch: {
    content (e) {
      console.log(this.$options.filters.dealImageUrl(e))
      if (!e) return
      const fileUrl = this.$options.filters.dealImageUrl(e)
      this.$nextTick(() => {
        this.pdfh5 = new Pdfh5('#demo', {
          pdfurl: fileUrl,
          lazy: true,
          cMapUrl: 'https://unpkg.com/pdfjs-dist@2.0.943/cmaps/' // 插件默认的地址证书过期了
        })
      }, 200)
    }
  },
  methods: {
    next () {
      this.$emit('next')
    },
    async onSign (e) {
      if (e === 1) { // 纸质签署
        const params = {
          proId: this.$route.query.proId,
          applyId: this.$route.query.id
        }
        await applyPaper(params)
        this.$router.replace({
          name: 'ConsentDetail',
          query: { id: this.$route.query.id }
        })
      } else { // 电子签署、问卷填写
        if (this.question.length > 0) {
          this.$refs.questionRef.open()
        } else {
          await startSign({
            applyId: this.$route.query.id,
            researcherNo: this.$route.query.researcherId
          })
          this.$router.replace({
            name: 'ConsentDetail',
            query: { id: this.$route.query.id }
          })
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .content{
    height: 800px;
    ::v-deep {
      img {
        max-width: 100%;
      }
    }
  }
  .bottom-view{
    padding: 20px 20%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .color{
      background-color: #FF8F9B;
      border-color: #FF8F9B;
    }
  }
</style>
